import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import firebase from "firebase";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { AuthContext } from "./AuthContext";

const Help = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const { currentUser } = useContext(AuthContext);
  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [reportDescription, setReportDescription] = useState('');
  const [reportEmail, setReportEmail] = useState('');
  const [reportErrorMessage, setReportErrorMessage] = useState('');
  const [submissionCount, setSubmissionCount] = useState(0);
  const maxSubmissions = 5;

  let flowId = WidgetStore.currentlySelectedFeedFlow == undefined ? EditorStore.flowId : WidgetStore.currentlySelectedFeedFlow;

  const saveReport = async () => {
    if (submissionCount >= maxSubmissions) {
      setReportErrorMessage('You have reached the maximum number of submissions. Please wait before submitting again.');
      return;
    }
    if (!reportTitle.trim() || !reportDescription.trim()) {
      setReportErrorMessage('Please fill in all required fields.');
      return;
    }
    try {
      const reportsRef = firebase.firestore().collection("help");
      await reportsRef.add({
        title: reportTitle.trim(),
        description: reportDescription.trim(),
        email: reportEmail.trim(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        currentUser: currentUser.displayName
      });
      setSubmissionCount(submissionCount + 1);
      setConfirm(true);
      setTimeout(() => setSubmissionCount(0), 30000);
    } catch (error) {
      console.error("Error saving report: ", error);
      setReportErrorMessage('There was an error submitting your report. Please try again.');
    }
  };

  return (
    <div style={{ width: '100%', margin: 'auto', borderRadius: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40, display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: 500, borderRadius: 20, overflow: 'hidden', width: '100%' }}>
        <div style={{ display: "flex", width: "100%", padding: "7px 25px", borderBottom: "1px solid #3E3E3E", alignItems: "center", maxWidth: 500, backgroundColor: '#1c1c1c' }}>
          <img style={{ width: 19.92, height: 19.76 }} src="/images/report-modal-icon.svg" />
          <div>
            <p style={{ color: "white", fontFamily: "quicksand", fontWeight: 700, fontSize: 14, opacity: 0.7, marginBottom: 10, marginTop: 10, marginLeft: 10 }}>
              Report a bug, ask for help or request a feature
            </p>
          </div>
          <div onClick={() => { WidgetStore.setShowReportModal(false); }} style={{ cursor: "pointer" }} />
        </div>
        {confirm === false ? (
          <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", marginTop: 55, padding: "20px 25px", paddingBottom: 30, backgroundColor: '#1c1c1c', maxWidth: 500, margin: 'auto' }}>
            <input className="formInputOutline" type="text" onChange={(e) => { setReportTitle(e.target.value); }} style={{ height: 40, width: "100%", marginBottom: 5, borderRadius: 10, paddingLeft: 10, backgroundColor: "#2D2A2B", border: "none", fontSize: 15, color: "white", fontFamily: "quicksand", fontWeight: "bold", background: "#323232", marginTop: 15 }} placeholder={"Report title"} value={reportTitle} />
            <p style={{ fontFamily: "Quicksand", fontWeight: 500, fontSize: 14, color: "#FFFFFF", opacity: 0.7, marginTop: 12 }}>Descriptions</p>
            <textarea className="formInputOutline" onChange={(e) => { setReportDescription(e.target.value) }} style={{ height: 170, width: "100%", borderRadius: 10, background: "#323232", marginTop: 15, marginBottom: 10, resize: "none", border: "none", padding: 10, fontSize: 14, color: "white", fontFamily: "quicksand", fontWeight: "bold" }} value={reportDescription} placeholder="Please give as much detail as possible, such as how to reproduce your bug, what or device" />
            <p style={{ fontFamily: "Quicksand", fontWeight: 500, fontSize: 14, color: "#FFFFFF" }}>Email (Optional)</p>
            <input className="formInputOutline" type="text" onChange={(e) => { setReportEmail(e.target.value) }} style={{ height: 33, width: "100%", marginBottom: 5, borderRadius: 10, paddingLeft: 10, background: "#323232", border: "none", marginTop: 15, fontSize: 15, color: "white", fontFamily: "quicksand", fontWeight: "bold" }} placeholder={"Email (Optional)"} value={reportEmail} />
            <p style={{ color: '#FF5C5C', fontSize: 12, fontFamily: 'quicksand', textAlign: 'center' }}>{reportErrorMessage}</p>
            <div onClick={() => { saveReport(); }} style={{ backgroundColor: "#4FD975", height: 35, width: "100%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 10, marginTop: 15, cursor: "pointer" }}>
              <p style={{ color: "#222222", fontFamily: "quicksand", fontSize: 15 }}>Submit</p>
            </div>
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 400px)", width: "100%", display: "flex", flexDirection: "column", marginTop: 55, padding: "20px 25px", maxHeight: 500, alignContent: 'center', justifyContent: 'center', backgroundColor: '#1c1c1c' }}>
            <p style={{ color: "white", fontFamily: "quicksand", fontSize: 20, textAlign: 'center' }}>Thank you!</p>
            <p style={{ color: "white", fontFamily: "quicksand", fontSize: 14, textAlign: 'left', marginBottom: 50, fontWeight: 100 }}>Your report has been submitted. You may receieve a follw up email from us.</p>
            <div onClick={() => { setConfirm(false); }} style={{ backgroundColor: "#4FD975", height: 35, width: "100%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 10, marginTop: 15, cursor: "pointer" }}>
              <p style={{ color: "#222222", fontFamily: "quicksand", fontSize: 15 }}>Go back</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default React.memo(Help);
