import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { firebase } from "../../../Components/firebase/firebase";
import { AuthenticationStoreContext } from "../../../store/AuthenticationStore";
import { CommentsStoreContext } from "../../../store/CommentsStore";
import { WidgetStoreContext } from "../../../store/WidgetStore";
import { RouteStoreContext } from "../../../store/RouteStore";
import { EditorStoreContext } from "../../../store/EditorStore";
import { AuthContext } from "../AuthContext";
import { RoomContext } from "../RoomContext";
import { useParams } from 'react-router-dom';
import ReactModal from "react-modal";
import NewComments from "../NewComments.tsx";
import RateLimit from "../RateLimit";
import moment from "moment";
let canComment;
const CommentsMobile = observer(() => {
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const CommentsStore = useContext(CommentsStoreContext);
  const RouteStore = useContext(RouteStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const { currentUser } = useContext(AuthContext);
  const room = useContext(RoomContext);
  const { roomId, commentId, replyId } = useParams();

  const [comments, setComments] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [commentNotExist, setCommentNotExist] = useState(false);
  const [openModalRateLimit, setOpenModalRateLimit] = useState(false);
  const [lastVisibleComment, setLastVisibleComment] = useState(null);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [isCommentsLoaded, setIsCommentsLoaded] = useState(null);
  const [commentNumber, setCommentNumber] = useState(null);
  const [isFlagComment, setIsFlagComment] = useState(null);

  const commentsPerPage = 10;
  



  useEffect(() => {
    
    if(commentId !== undefined && commentId !== null) {
    console.log(`Attempting to scroll to comment: ${commentId}`);
    // Using a slightly longer timeout to ensure elements are rendered
    const timer = setTimeout(() => {
      const element = document.getElementById(`${commentId}`);

      if (element) {
        console.log(`Found element for commentId ${commentId}, scrolling into view.`);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        element.style.border = '5px solid #FF5C5C';

   
      } else {
        console.log(`Element for commentId ${commentId} not found.`);

      }
      setTimeout(()=>{
        const reply = document.getElementById(`${replyId}`);

        if (reply) {
          console.log(`Found element for commentId ${replyId}, scrolling into view.`);
          reply.scrollIntoView({ behavior: 'smooth', block: 'start' });
          reply.style.border = '5px solid #FF5C5C';
  
     
        } else {
          console.log(`Element for commentId ${replyId} not found.`);

        }

        if(!element && !reply) {
          setCommentNotExist(true);
        }


      },1000)
    }, 1000); // Adjust time as needed based on your app's rendering timing
  
    // Cleanup timeout if component unmounts
    return () => clearTimeout(timer);
  }
  }, [commentId]);


  const isComment = (id) => {
    for (let i = 0; i < isCommentsLoaded.length; i++) {
      if (isCommentsLoaded[i] === id) {
        return true;
      }
    }
  };

  useEffect(() => {
    // If EditorStore.flowId is empty or not set, use roomId from the URL
    const flowId = roomId || (EditorStore.flowId !== "" ? EditorStore.flowId : room.flowId);
  
    // const loadCommentsAndLikes = async () => {
    //   const commentsSnapshot = await firebase.firestore().collection("comments")
    //     .where("flowId", "==", flowId)
    //     .orderBy("date", "desc")
    //     .limit(10)
    //     .get();
  
    //   // Initialize an array to hold the comments with their likes count
    //   const commentsWithLikes = await Promise.all(commentsSnapshot.docs.map(async (doc) => {
    //     const data = doc.data();
    //     data.commentId = doc.id;
  
    //     // Query for likes for this particular comment
    //     const likesSnapshot = await firebase.firestore().collection("commentsLikes")
    //       .where("commentId", "==", doc.id)
    //       .get();
  
    //     // Count of likes for the comment
    //     data.likesCount = likesSnapshot.size;
  
    //     return data;
    //   }));
  
      // setCommentNumber(commentsWithLikes.length);
      // setComments(commentsWithLikes);
      setIsLoaded(true);
      CommentsStore.setIsLoaded(true);
   // };
  
    // loadCommentsAndLikes();
  
    return () => {
      // Clean up actions here if necessary
    }
  },[]); 
  

  useEffect(() => {
    if (CommentsStore.isAddComment) {
      console.log(CommentsStore.comment);
      addComment(CommentsStore.comment);
      CommentsStore.setIsAddComment(false);
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [CommentsStore.isAddComment]);

  useEffect(() => {
    if (CommentsStore.isDeleteComment) {
      deleteComment(CommentsStore.comment);
      CommentsStore.setIsDeleteComment(false);
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [CommentsStore.isDeleteComment]);

  useEffect(() => {
    if (CommentsStore.isFlagComment) {
      flagComment(CommentsStore.comment);
      CommentsStore.setIsFlagComment(false);
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [CommentsStore.isFlagComment]);

  const addComment = async (comment) => {
    setComments([comment, ...comments]);

    //if (canComment) {
      // post the comment
      canComment = false;
      //firebase.auth().onAuthStateChanged(async (user) => {
        //if (user) {
          console.log("currentUser", currentUser);
          addCommentNotification(
            currentUser.displayName,
            comment.username,
            "comment",
            comment.flowId
          );
          await firebase
            .firestore()
            .collection("comments")
            .doc(comment.commentId)
            .set({
              comment: comment.comment,
              commentId: comment.commentId,
              date: comment.date,
              flowId: comment.flowId,
              urlPhoto: comment.urlPhoto,
              userId: comment.userId,
              username: comment.username,
            });

          incrementCommentsCount(roomId);
          // setComments([...comments, comment]);

          // let currentUser = firebase.auth().currentUser;

          // window.analytics.track("Flow Commented", {
          //   flowId: comment.flowId,
          //   commentId: comment.commentId,
          //   actionUserId: currentUser?.uid,
          //   actionUsername: currentUser?.displayName,
          //   comment: comment.comment,
          // });
        //}
      //});

    //   setTimeout(() => {
    //     canComment = true;
    //   }, 5000);
    // } else {
    //   props.close();
    //   console.log("You must wait before posting another comment.");
    //   WidgetStore.setTryAgain(true);
    // }
  };

  const deleteComment = (comment) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const firestore = firebase.firestore();
        const batch = firestore.batch();
  
        // Delete the main comment
        const commentRef = firestore.collection("comments").doc(comment.commentId);
        batch.delete(commentRef);
  
        // Functions to delete related documents in other collections
        const deleteRelatedDocuments = async (collectionName) => {
          const querySnapshot = await firestore.collection(collectionName).where("commentId", "==", comment.commentId).get();
          querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
        };
  
        // Await deletion of related documents in other collections
        await Promise.all([
          deleteRelatedDocuments("commentReplies"),
          deleteRelatedDocuments("commentsLikes"),
          deleteRelatedDocuments("notifications"),
        ]);
  
        // Commit the batch
        await batch.commit();
  
        decrementCommentsCount(comment.flowId);
  
        let commentsNew = comments.filter((commentEl) => commentEl.commentId != comment.commentId);
        setComments(commentsNew);
  
        window.analytics.track("Flow Deleted", {
          flowId: comment.flowId,
          commentId: comment.commentId,
          actionUserId: currentUser?.uid,
          actionUsername: currentUser?.displayName,
          comment: comment.comment,
        });
      }
    });
  };
  

  const flagComment = (comment) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await firebase
          .firestore()
          .collection("flags")
          .doc(comment.commentId)
          .set(comment);

        incrementFlagsCount(comment.flowId);

        window.analytics.track("Flow Flagged", {
          flowId: comment.flowId,
          commentId: comment.commentId,
          actionUserId: currentUser?.uid,
          actionUsername: currentUser?.displayName,
          comment: comment.comment,
        });

        setIsFlagComment(true);
      }
    });
  };

  const incrementCommentsCount = (flowId) => {
    firebase
      .firestore()
      .collection("flows")
      .doc(flowId)
      .set(
        { comments: firebase.firestore.FieldValue.increment(1) },
        { merge: true }
      );
    CommentsStore.setCommentsCount(CommentsStore.commentsCount + 1);
    CommentsStore.setIncrementCommentsCount(true);
  };

  const decrementCommentsCount = (flowId) => {
    firebase
      .firestore()
      .collection("flows")
      .doc(flowId)
      .set(
        { comments: firebase.firestore.FieldValue.increment(-1) },
        { merge: true }
      );

    CommentsStore.setCommentsCount(CommentsStore.commentsCount - 1);
    CommentsStore.setDecrementCommentsCount(true);
  };

  const incrementFlagsCount = (flowId) => {
    firebase
      .firestore()
      .collection("flows")
      .doc(flowId)
      .set(
        { flags: firebase.firestore.FieldValue.increment(1) },
        { merge: true }
      );
  };

  const onCloseFlagComment = () => {
    setIsFlagComment(false);
  };


  const loadCommentsAndLikes = async (initialLoad = false) => {
    const flowId = roomId || (EditorStore.flowId !== "" ? EditorStore.flowId : room.flowId);
    let query = firebase.firestore()
      .collection("comments")
      .where("flowId", "==", flowId)
      .orderBy("date", "desc")
      .limit(10);

    if (!initialLoad && lastVisibleComment) {
      query = query.startAfter(lastVisibleComment);
    }

    const commentsSnapshot = await query.get();

    if (commentsSnapshot.empty) {
      setHasMoreComments(false);
      return;
    }

    const lastVisible = commentsSnapshot.docs[commentsSnapshot.docs.length - 1];
    setLastVisibleComment(lastVisible);

    const newComments = await Promise.all(
      commentsSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        data.commentId = doc.id;

        const likesSnapshot = await firebase.firestore()
          .collection("commentsLikes")
          .where("commentId", "==", doc.id)
          .get();

        data.likesCount = likesSnapshot.size;

        return data;
      })
    );

    setComments((prevComments) => [...prevComments, ...newComments]);
    setIsLoaded(true);
    CommentsStore.setIsLoaded(true);
  };

  useEffect(() => {
    loadCommentsAndLikes(true);
  }, []);

  const addCommentNotification = async (sender, receiver, commentContent, flowId) => {
    const newNotification = {
      sender,
      receiver,
      type: "commentLeft",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      additionalData: { commentContent, flowId },
    };
    await firebase.firestore().collection("notifications").add(newNotification);
  };

  return (
    <div style={{ height: "calc(100% - 70px)", width: "100%", background: "#1C1C1C", borderTop: "1px solid #3E3E3E", overflowY: "scroll", paddingBottom:10 }}>
      <NewComments
        key={Math.random()}
        comments={comments}
        flowId={CommentsStore.flowId}
        isLoaded={CommentsStore.isLoaded}
        myUserId={AuthenticationStore.userId}
      />
      {hasMoreComments ? (
        <button onClick={() => loadCommentsAndLikes()} style={{ fontWeight: 'bold', color: 'white', fontFamily: 'quicksand', backgroundColor:'transparent', border:'none', width:'100%' }}>
          Load More...
        </button>
      ) : (
        <p style={{ color: 'white', fontFamily: 'quicksand', textAlign:'center', fontSize:11 }}></p>
      )}
 
      <ReactModal
        ariaHideApp={true}
        isOpen={commentNotExist}
        closeTimeoutMS={200}
        onAfterClose={() => setCommentNotExist(false)}
        style={{
          overlay: {
            padding: 0, zIndex: 1000000, display: "flex", justifyContent: "center",
            alignItems: "center", background: "rgba(0, 0, 0, 0.8)", 
          },
          content: {
            position: "relative", top: "0px", left: "0px", right: "0px", bottom: "0px",
            backgroundColor: "#1C1C1C", borderRadius: "20px", outline: "none",
            padding: "0px", margin: "auto", width: "calc(100% - 50px)", maxWidth: "300px",
            border:'none'
          }
        }}
      >
        <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        />

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Comment Not Found!
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            setCommentNotExist(false)
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>
      <div style={{borderTop: '1px solid rgb(62, 62, 62)'}}></div>
        <div style={{ height: 250, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', paddingLeft:20, paddingRight:20 }}>
        <img
      
          src="/images/warning.svg"
          style={{ cursor: "pointer", height:70, width:70, marginBottom:20 }}
        />
          <p style={{ color: 'white', fontFamily: 'quicksand', fontSize: 14, textAlign:'center' }}>The comment you're looking for cannot be found.</p>
          <div onClick={() => setCommentNotExist(false)} style={{ height: 30, width: 100, backgroundColor: '#4FD975', display:'flex', justifyContent:'center', alignItems:'center', marginTop:15, borderRadius:10, cursor:'pointer' }}>
            <p style={{ color: '#1c1c1c', fontFamily:'quicksand' }}>Ok</p>
          </div>
        </div>
      </ReactModal>
      <RateLimit openModalRateLimit={openModalRateLimit} closeError={() => setOpenModalRateLimit(false)} />
    </div>
  );
});

export default CommentsMobile;
