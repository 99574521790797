import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Updated import
// import styled from "styled-components";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { ProfileStoreContext } from "../../store/ProfileStore.js";
// libraries
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "./AuthContext";
import { firebase } from "../firebase/firebase";
import PromoAgreement from "./PromoAgreement";
import { Link, useParams } from "react-router-dom";




// let CenteredCard = styled.div`
//   width: 100%;
//   max-width: 600px;
//   margin: auto;
//   display: grid;
//   align-items: center;
//   justify-content: center;
//   grid-template-rows: auto;
//   grid-template-columns: auto;
//   grid-template-areas:
//     ". name ."
//     ". avatar ."
//     "bio bio bio"
//     ". button .";

//   button {
//     margin: auto;
//   }
// `;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 12,
      width: "100%",
      backgroundColor: "rgb(32, 32, 32)",
    },
    header: {
      color: "white",
    },
  })
);

export default function ProfileCard({
  photoURL,
  bio,
  username,
  fullname,
  userId,
  followings,
  followers,
  likes,
  isFollowing,
  followFetchStatus,
  toggleFollow,
}: {
  username: string;
  photoURL: string;
  bio: string;
  match: any;
  fullname: string;
  userId: string;
  followings: number;
  followers: number;
  likes: number;
  isFollowing: boolean;
  followFetchStatus: "pending" | "resolved" | "error";
  toggleFollow: (userId: string) => void;
}) {
  const classes = useStyles();
  let AuthenticationStore = useContext(AuthenticationStoreContext);
  let ProfileStore = useContext(ProfileStoreContext);
  let params = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [followerSelected, setFollowerSelected] = React.useState(false);
  const [followingSelected, setFollowingSelected] = React.useState(false);
  const [likeSelected, setLikeSelected] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [openPromo, setOpenPromo] = React.useState(false);
  const { currentUser } = useContext(AuthContext);
  const [isButtonClickable, setIsButtonClickable] = useState(true);

  const [totalLikes, setTotalLikes] = useState(0);
  let { id } = useParams(); // If you need to access params

  const fetchTotalLikes = async () => {
    const getTotalLikesForUserFlows = firebase.functions().httpsCallable('getTotalLikesForUserFlows');
    try {
      const result = await getTotalLikesForUserFlows({ userId: id });  // Assume 'id' is available from useParams or similar
      setTotalLikes(result.data.totalLikes);
    } catch (error) {
      console.error("Error fetching total likes: ", error);
    }
  };
  
  useEffect(() => {
    fetchTotalLikes();
  }, [id]); // Re-run when 'id' changes
  

  const handleButtonClick = () => {
    if (isButtonClickable) {
      AuthenticationStore.setShowUserModal(true);
      setIsButtonClickable(false); // Disable the button

      // Re-enable the button after 3 seconds
      //setTimeout(() => {
        setIsButtonClickable(true);
      //}, 100);
    }
  };
  let navigate = useNavigate(); // Changed from useHistory to useNavigate
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      id="profile-card"
      style={{
        width: "calc(100% - 29px)",
        background: "#1C1C1C",
        position: "relative",
        display: "flex",
        maxWidth: 1000,
        marginBottom: "23px",
        margin: "auto",
        zIndex: 100,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        marginTop: 110,
      }}
    >
      <div
        style={{
          height: 58,
          width: "100%",
          background: "#1C1C1C",
          maxWidth: 1000,
          position: "absolute",
          top: -62,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 18,
            flexDirection: "row",
            display: "flex",
            position: "relative",
            top: 15,
            left: 15,
          }}
        >
          <img src="./images/profile-icon.svg" style={{ top: 20, left: 18 }} />
          <p
            style={{ color: "white", fontFamily: "quicksand", marginLeft: 10 }}
          >
            Profile
          </p>
        </div>
      </div>
      <div style={{ display: "flex" }} id="name-user">
        <div>
          <div
            id="profile-pic"
            style={{
              backgroundImage: photoURL
                ? `url(${photoURL})`
                : `url(/images/Flowroom_Default_Avatar.png)`,
            }}
            onClick={() => {
              navigate(`/${username}`); // Updated navigation call
            }}
          >
            {" "}
            <img
              src="../images/verified.svg"
              style={{ position: "absolute", bottom: "7px", right: "-12px" }}
            />
          </div>
        </div>
        <div id="name-user-name" style={{ gridArea: "name", color: "white" }}>
          <p
          id="name-user-name-fullname"
            style={{
              fontFamily: "quicksand",
              whiteSpace: "nowrap",
              fontSize: 21,
            }}
          >
            {fullname}
          </p>
          <p
          id="username-card"
            style={{
              paddingBottom: 17,
              fontSize: 13,
              fontFamily: "quicksand",
              fontWeight: "lighter",

              /* margin-bottom: 1px; */
              position: "relative",
              bottom: "7px",
            }}
          >{`@${username}`}</p>
        </div>
      </div>
      {/* <CenteredCard> */}

      <div id="name-card-wrap" style={{ display: "flex" }}>
        <div id="name-user-name-card-wrapper" style={{ display: "flex", justifyContent: "space-between", marginBottom:40 }}>
          <div
            id="name-user-name-card"
            style={{ gridArea: "name", color: "white" }}
          >
            <p
              style={{
                fontFamily: "quicksand",
                whiteSpace: "nowrap",
                fontSize: 21,
              }}
            >
              {fullname}
            </p>
            <p
              style={{
                fontSize: 13,
                fontFamily: "quicksand",
                fontWeight: "lighter",

                /* margin-bottom: 1px; */
                position: "relative",
                bottom: "7px",
                marginTop: 7,
              }}
            >{`@${username}`}</p>
          </div>
          <div
            id="followers-and-likes-desktop"
            style={{
              width: "255px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                onClick={() => {
                  // ProfileStore.setFollowerSelected(false);
                  // ProfileStore.setFollowingSelected(true);
                  // ProfileStore.setLikeSelected(false);
                  setFollowerSelected(false);
                  setFollowingSelected(true);
                  setLikeSelected(false);
                  ProfileStore.setProfileItemSelected("following");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  background: "#2C2C2C",
                  border: "1px solid #4FD975",
                  width: 78,
                  height: 75,
                  position: "relative",
                  borderRadius: "15px",
                  cursor: "pointer",
                  border:
                    ProfileStore.profileItemSelected === "following"
                      ? "1px solid #4FD975"
                      : "1px solid transparent",
                }}
              >
                <p
                  style={{
                    color:
                      ProfileStore.profileItemSelected === "following"
                        ? "#4FD975"
                        : "#FFF",
                    fontWeight: 500,
                    fontSize: 21,
                    fontFamily: "quicksand",
                    fontStyle: "normal",
                    marginTop: 8,
                  }}
                >
                  {followings}
                </p>
                <p
                  style={{
                    bottom: "7px",
                    position: "relative",
                    fontFamily: "Quicksand",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 12,
                    color:
                      ProfileStore.profileItemSelected === "following"
                        ? "#4FD975"
                        : "rgba(255, 255, 255, 0.6)",
                    marginTop: 14,
                  }}
                >
                  {"Following"}
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                setFollowerSelected(true);
                setFollowingSelected(false);
                setLikeSelected(false);
                ProfileStore.setProfileItemSelected("followers");
              }}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                background: "#2C2C2C",
                width: 78,
                height: 75,
                position: "relative",
                borderRadius: "15px",
                cursor: "pointer",
                border:
                  ProfileStore.profileItemSelected === "followers"
                    ? "1px solid #4FD975"
                    : "1px solid transparent",
              }}
            >
              <p
                style={{
                  color:
                    ProfileStore.profileItemSelected == "followers"
                      ? "#4FD975"
                      : "#FFF",
                  fontWeight: 500,
                  fontSize: 21,
                  fontFamily: "quicksand",
                  fontStyle: "normal",
                  marginTop: 8,
                }}
              >
                {followers}
              </p>
              <p
                style={{
                  color:
                    ProfileStore.profileItemSelected === "followers"
                      ? "#4FD975"
                      : "rgba(255, 255, 255, 0.6)",
                  marginTop: 14,
                  fontSize: 12,
                  fontFamily: "quicksand",
                  bottom: "7px",
                  position: "relative",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                {"Followers"}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                onClick={() => {
                  setFollowerSelected(false);
                  setFollowingSelected(false);
                  setLikeSelected(true);

                  ProfileStore.setProfileItemSelected("likes");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  background: "#2C2C2C",
                  width: "78px",
                  height: "75px",
                  position: "relative",
                  borderRadius: "15px",
                  cursor: "pointer",
                  border:
                    ProfileStore.profileItemSelected === "likes"
                      ? "1px solid #4FD975"
                      : "1px solid transparent",
                }}
              >
                <p
                  style={{
                    color:
                      ProfileStore.profileItemSelected === "likes"
                        ? "#4FD975"
                        : "#FFF",
                    fontWeight: 500,
                    fontSize: 21,
                    fontFamily: "quicksand",
                    position: "relative",
                    marginTop: 8,
                  }}
                >
                  {totalLikes}
                </p>
                <p
                  style={{
                    color:
                      ProfileStore.profileItemSelected === "likes"
                        ? "#4FD975"
                        : "rgba(255, 255, 255, 0.6)",
                    fontSize: 12,
                    fontFamily: "quicksand",
                    bottom: "10px",
                    position: "relative",
                    fontStyle: "normal",
                    fontWeight: 500,
                    marginTop: 14,
                  }}
                >
                  {"Likes"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            gridArea: "bio",

            textAlign: "center",
            color: "white",
            maxWidth: "100%",
          }}
        >
          <p
            style={{
              fontFamily: "quicksand",
              fontSize: 12.5,
              flexWrap: "wrap",
              display: "flex",
              textAlign: "left",
              lineHeight: "15px",
              position: "relative",
              top: "2px",
            }}
          >
            {bio}
          </p>
          <div
            id="followers-and-likes-mobile"
            style={{
              width: "270px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                onClick={() => {
                  // ProfileStore.setFollowerSelected(false);
                  //ProfileStore.setFollowingSelected(true);
                  // ProfileStore.setLikeSelected(false);
                  setFollowerSelected(false);
                  setFollowingSelected(true);
                  setLikeSelected(false);
                  ProfileStore.setProfileItemSelected("following");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  background:"#2C2C2C",
                  height: "59px",
                  width: "80px", 
                  position: "relative",
                  borderRadius: "15px",
                  cursor: "pointer",
                  border:`1px solid ${ProfileStore.profileItemSelected === "following" ? '#4FD975':'transparent'}`,
                  margin:5
                }}
              >
                <p
                  style={{
                    color:`${ProfileStore.profileItemSelected === "following" ? '#4FD975':'#FFF'}`,
                    fontWeight: 'lighter',
                    fontSize: 21,
                    fontFamily: "quicksand",
                  }}
                >
                  {followings}
                </p>
                <p
                  style={{
                    color:'#FFF',
                    fontSize: 12,
                    fontFamily: "quicksand",
                    bottom: "7px",
                    position: "relative",
                    opacity:`${ProfileStore.profileItemSelected === "following" ? 1:0.5}`, 
                    marginTop:7,
                    color:`${ProfileStore.profileItemSelected === "following" ? '#4FD975':'#FFF'}`,
                    fontWeight:'lighter'
                  }}
                >
                  {"Following"}
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                setFollowerSelected(true);
                setFollowingSelected(false);
                setLikeSelected(false);
                ProfileStore.setProfileItemSelected("followers");
              }}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                background:"#2C2C2C",
                height: "59px",
                width: "90px", 
                position: "relative",
                borderRadius: "15px",
                cursor: "pointer",
                border:`1px solid ${ProfileStore.profileItemSelected === "followers" ? '#4FD975':'transparent'}`,
                margin:5
              }}
            >
              <p
                style={{
                  fontWeight: 'lighter',
                  fontSize: 21,
                  fontFamily: "quicksand",
                  color:`${ProfileStore.profileItemSelected === "followers" ? '#4FD975':'#FFF'}`,

                }}
              >
                {followers}
              </p>
              <p
                style={{
                  color:"#FFF",
                  fontSize: 12,
                  fontFamily: "quicksand",
                  fontWeight:'lighter',
                  bottom: "7px",
                  position: "relative",
                  opacity:0.5,
                  marginTop:7,
                  opacity:`${ProfileStore.profileItemSelected === "followers" ? 1:0.5}`, 
                  color:`${ProfileStore.profileItemSelected === "followers" ? '#4FD975':'#FFF'}`,
                }}
              >
                {"Followers"}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                onClick={() => {
                  setFollowerSelected(false);
                  setFollowingSelected(false);
                  // setLikeSelected(true);

                   ProfileStore.setProfileItemSelected("favorites");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  background:"#2C2C2C",
                  height: "59px",
                  width: "80px", 
                  position: "relative",
                  borderRadius: "15px",
                  cursor: "pointer",
                  border:`1px solid ${ProfileStore.profileItemSelected === "favorites" ? '#4FD975':'transparent'}`,
                  margin:5
                }}
              >
                <p
                  style={{
                    color:"#FFF",
                    fontWeight: 'lighter',
                    fontSize: 23,
                    fontFamily: "quicksand",
                    position: "relative",
                    // opacity:`${ProfileStore.profileItemSelected === "favorites" ? 1:0.5}`, 
                    color:`${ProfileStore.profileItemSelected === "favorites" ? '#4FD975':'#FFF'}`,
                  }}
                >
                  {totalLikes}
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontFamily: "quicksand",
                    fontWeight:'lighter',
                    bottom: "10px",
                    position: "relative",
                    opacity:`${ProfileStore.profileItemSelected === "favorites" ? 1:0.5}`, 
                    marginTop:7,
                    color:`${ProfileStore.profileItemSelected === "favorites" ? '#4FD975':'#FFF'}`,

                  }}
                >
                  {"Likes"}
                </p>
              </div>
            </div>
          </div>
        </div>
        {currentUser !== null && params.id !== currentUser.displayName? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              disabled={isDisabled}
              style={{
                gridArea: "button / button / button / button",
                marginTop: "27px",
                marginBottom: "24px",
                color: "rgb(63, 63, 62)",
                backgroundColor: "#4FD975",
                borderRadius: "10px",
                fontFamily: "quicksand",
                fontSize: "20px",
                fontWeight: 900,
                textTransform: "none",
                letterSpacing: "0px",
                height: "50px",
                border: "0px",
                width: "100%",
              }}
              onClick={() => {
                
                if (currentUser == null) {
                  AuthenticationStore.setShowModal(true);
                } else {
                  setIsDisabled(true);
                  setTimeout(() => {
                    setIsDisabled(false);
                  });
                  toggleFollow(userId, username, true);
                }
              }}
              color="primary"
              variant={isFollowing ? "contained" : "outlined"}
            >
              {isFollowing ? "Following" : "Follow"}
            </Button>
            {currentUser !== null && params.id !== currentUser.displayName ? (
              <Link to={`/messages/${username}`}>
                <div
                  style={{
                    zIndex: 10000,
                    cursor: "pointer",
                    background: "#6371F6",
                    height: 40,
                    width: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    pointerEvents: "all",
                    position: "relative",
                    marginLeft: 20,
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    color="#222222"
                    style={{
                      margin: 10,
                      cursor: "pointer",
                      display: "block",
                      fontSize: 25,
                    }}
                  />
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {currentUser !== null && params.id == currentUser.displayName && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                gridArea: "button",
                marginTop: "35px",
                marginBottom: "24px",
                color: "#3F3F3E",
                backgroundColor: "#A962F1",
                fontFamily: "quicksand",
                fontSize: "20px",
                fontWeight: 900,
                textTransform: "none",
                letterSpacing: 0,
                border: "0px",

                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 40,
                background: "#4FD975",
                borderRadius: 10,
                width: "100%",
              }}
              onClick={() => {
                AuthenticationStore.setShowUserModal(true);
              }}
              color="primary"
              variant="contained"
              disabled={!isButtonClickable} 
            >
              Edit Profile
            </Button>

            {currentUser !== null && params.id !== currentUser.displayName ? (
              <Link to={`/messages/${username}`}>
                <div
                  style={{
                    zIndex: 10000,
                    cursor: "pointer",
                    background: "#6371F6",
                    height: 40,
                    width: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    pointerEvents: "all",
                    position: "relative",
                    marginLeft: 20,
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    color="#222222"
                    style={{
                      margin: 10,
                      cursor: "pointer",
                      display: "block",
                      fontSize: 25,
                    }}
                  />
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        )}
        <div onClick={()=>{
              setOpenPromo(true);
            }} style={{    backgroundColor: 'white',
              /* height: 30px; */
              /* width: 100px; */
              fontFamily: 'Quicksand',
              color: '#5B5AD8',
              borderRadius: 15,
              /* padding-left: 10px; */
              whiteSpace: 'nowrap',
              maxWidth: 120,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 3, display:'none'}}>
        <p style={{    fontFamily: 'Quicksand',
    fontSize: 14, cursor:'pointer'}}>Book promo</p>
        </div>
      </div>

      {/* </CenteredCard> */}
      {/* <CenteredCard> */}

      {/* </CenteredCard> */}
      {/* <CenteredCard> */}

      {/* </CenteredCard> */}
      <PromoAgreement openPromoAgreement={openPromo} closePromoAgreement={()=>{
        setOpenPromo(false);
      }}/>
    </div>
  );
}
