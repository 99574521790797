import * as ElasticAppSearch from "@elastic/app-search-javascript";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
// import ScrollMenu, { VisibilityContext } from "react-horizontal-scrolling-menu";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { ProfileStoreContext } from "../../store/ProfileStore.js";

import { firebase } from "../firebase/firebase";
import PaymentModal from "./PaymentModal.js";
import ReportModal from "./ReportModal";
import { RoomProvider } from "./RoomContext.js";
import RoomPost from "./RoomPost.js";
import PromoAgreement from "./PromoAgreement.js";
import { Config } from "./config/Config";
import SigninM from "./SigninM.js";
import { AuthContext } from "./AuthContext";
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate  } from 'react-router-dom';
import { faBan, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const masonryOptions = {
  transitionDuration: 0,
};
let currentUserString = localStorage.getItem("flowroom-user");

const imagesLoadedOptions = { background: ".my-bg-image-el" };

const getPostWidth = () => {
  if (window.innerWidth > 2000) {
    return window.innerWidth * 0.25;
  } else if (window.innerWidth > 1430) {
    return window.innerWidth * 0.35;
  } else if (window.innerWidth > 640) {
    return window.innerWidth * 0.4;
  } else {
    return window.innerWidth * 0.9;
  }
};
const roomsPerPage = 4;  // Limit rooms per page to 4
let lastDocs = [];  // To keep track of the last document snapshot for each page

let firestore = firebase.firestore();
var x = window.matchMedia("(max-width: 800px)");
let isCurrentlyMobileTabletResolution;
let isCurrentlyDesktopResolution;
let typingTimer;
const Arrow = ({ text, className }) => {
  // return <div className={className}>{text}</div>;
  return (
    <i
      className="fas fa-chevron-right"
      style={{ color: "#A962F1", fontSize: 12 }}
    ></i>
  );
};
Arrow.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

// function LeftArrow() {
//   const { isFirstItemVisible, scrollPrev } =
//     React.useContext(VisibilityContext);

//   return (
//     <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//       <ArrowLeft />
//     </Arrow>
//   );
// }

// function RightArrow() {
//   const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

//   return (
//     <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
//       <ArrowRight />
//     </Arrow>
//   );
// }

function isCurrentDevice(x) {
  if (x.matches) {
    isCurrentlyMobileTabletResolution = true;
    isCurrentlyDesktopResolution = false;
  } else {
    isCurrentlyDesktopResolution = true;
    isCurrentlyMobileTabletResolution = false;
  }
}
var eoptions = {
  search_fields: {
    description: {},
    collaborators: {},
    filters: {},
    thumbnail: {},
    title: {},
    date: {},
    flowid: {},
    comments: {},
    likes: {},
    creator: {},
    creatorprofilepic: {},
    username: {},
  },
  result_fields: {
    description: {
      raw: {},
    },
    collaborators: { raw: {} },
    filters: { raw: {} },
    thumbnail: { raw: {} },
    title: { raw: {} },
    date: { raw: {} },
    flowid: { raw: {} },
    comments: { raw: {} },
    likes: { raw: {} },
    creator: { raw: {} },
    creatorprofilepic: { raw: {} },
    username: { raw: {} },
  },
};
const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? "active" : ""}`}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {text}
    </div>
  );
};

var client = ElasticAppSearch.createClient({
  searchKey: "search-au4byfvb1sdmttpm5w1uw26s",
  endpointBase: "https://flowroom.ent.eu-west-1.aws.found.io",
  engineName: "flowroom",
});

isCurrentDevice(x); // Call listener function at run time
x.addListener(isCurrentDevice); // Attach listener function on state changes
export const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
export const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });

  let currentTabId = "flows";
  let userId = "";
  let urlPhoto = "";
  let flowIds = {};
  let rooms = [];
  let roomPosts = [];
  let favorites = [];
  let followers = [];
  let followings = [];
  let favoriteUserIds = {};
  let lastIndexFlows = null;
  let lastIndexLikes = null;
  let lastIndexFollowers = null;
  let lastIndexFollowings = null;
  let followersPerPage = window.innerWidth >= 1024 ? 9 : 9;
  let followingsPerPage = window.innerWidth >= 1024 ? 9 : 9;
  let roomFilter = "date";
  let ROOM_ASPECT_RATIO = 1;
  let timer = null;
  let thumbnailListeners = {};
  let limitFlowIds = [];
  let postWidth = getPostWidth();
  // let postHeight = postHeight;
  
  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  let marginVertical = isMobile ? 40 : 20;
  let marginTopHeader = isMobile ? 55 : 55;
  let username = "";

  const style = {
    height: 20,
    width: 20,
    border: "1px solid green",
    margin: 6,
    padding: 8,
  };
  
const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const ScrollProfile = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [rooms, setRooms] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 4; // Number of items per page, adjust as needed
  const [lastDocs, setLastDocs] = useState([]);

  const [tryAgain, setTryAgain] = useState(false);
  const [hasMoreRooms, setHasMoreRooms] = useState(true);
  const [roomsList, setRoomsList] = useState(true);
  const [openPromo, setOpenPromo] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const [lastDoc, setLastDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState(null);
  const [loadingFlows, setLoadingFlows] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [loading, setLoading] = useState(true);

  const ProfileStore = useContext(ProfileStoreContext)
  const { id } = useParams();

  const [isProfileViewable, setIsProfileViewable] = useState(false); // Check if profile can be viewed
  const db = firebase.firestore();

  useEffect(() => {
    const checkDocumentExistsAndBlockedStatus = async () => {

      

        // Query the users collection to get the userId of the room owner
        const userSnapshot = await firebase.firestore()
          .collection('users')
          .where('username', '==', id)
          .get();

        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          const userData = userDoc.data();
          const targetUserId = userData.userId;

          // Query the blocked collection to check if current user is blocked
          const blockedSnapshot = await firebase.firestore()
            .collection('blocked')
            .where('userId', '==', targetUserId)
            .get();

          blockedSnapshot.forEach((blockedDoc) => {
            const blockedData = blockedDoc.data();
            if (blockedData.blockedUser === currentUser.displayName) {
              setIsBlocked(true); // Set blocked status to true
            }
          });
        }
    
    };

    checkDocumentExistsAndBlockedStatus();
  }, [id, currentUser]);

  // Fetch userId from users collection where username matches the `id` (profile owner's username)
  useEffect(() => {
    if (id) {
      // Check if viewing own profile
      if (currentUser !== null && id === currentUser.displayName ) {
        // Automatically viewable if it's your own profile
        setIsPrivate(false);
        setIsProfileViewable(true);
        return; // Exit the effect since no further check is needed
      }

      db.collection("users")
        .where("username", "==", id) // Assume id is the username of the profile being viewed
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const userData = doc.data();
            const targetUserId = userData.userId; // Assuming user document has a userId field
            // setIsPrivate(userData.isPrivate || false); // Set isPrivate state based on Firestore data

            // If profile is private, check follow relationship
            if (userData.isPrivate) {
              if(currentUser == null) {
                setIsPrivate(true);
                return;
              }
              checkFollowRelationship(targetUserId); // Call function to check follow relationship
            } else {
              setIsProfileViewable(true); // Profile is not private, viewable by default
              setIsPrivate(false);

            }
            
          }
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }
  }, [id, currentUser]);

  // Function to check the follow relationship between the currentUser and the profile user
  const checkFollowRelationship = (targetUserId) => {
    if (currentUser && targetUserId) {
      const currentUserId = currentUser.uid;
      // Query 1: Check if currentUser follows the target user
      db.collection("followers")
        .where("userId", "==", targetUserId)
        .where("followerUserId", "==", currentUserId)
        .get()
        .then((querySnapshot1) => {
          if (!querySnapshot1.empty) {
            // If currentUser follows the target user, check if the target user follows back
            db.collection("followers")
              .where("userId", "==", currentUserId)
              .where("followerUserId", "==", targetUserId)
              .get()
              .then((querySnapshot2) => {
                if (!querySnapshot2.empty) {
                  // If target user follows currentUser back, profile is viewable
                  setIsProfileViewable(true);
                  setIsPrivate(false);

                } else {
                  // Not mutual following, profile is not viewable
                  setIsProfileViewable(false);
                  setIsPrivate(true);

                }
              })
              .catch((error) => {
                console.error("Error checking if target user follows currentUser: ", error);
              });
          } else {
            // currentUser does not follow target user, profile is not viewable
            setIsProfileViewable(false);
            setIsPrivate(true);

          }
        })
        .catch((error) => {
          console.error("Error checking if currentUser follows target user: ", error);
        });
    }
  };





  useEffect(()=>{
    
  },[ProfileStore.profileItemSelected])

  function smoothScrollToTop(duration) {
    const targetPosition = 0;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
      else window.scrollTo(0, targetPosition); // Ensure it scrolls to the very top
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  }


  useEffect(()=>{
    setIsLoading(true);

    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  },[])

  useEffect(() => {
    loadRooms(currentPage);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
    // WidgetStore.setCurrentlySelectedFilter("recent");
  }, [currentPage, WidgetStore.currentlySelectedFilter]);
  const handlePageChange = (newPage) => {
    if (newPage < 0) {
        console.warn("Attempted to navigate to a negative page. Ignoring.");
        return;
    }
    if (newPage >= lastDocs.length && !hasMoreRooms) {
        console.warn("Attempted to navigate beyond available pages. Ignoring.");
        return;
    }
    setCurrentPage(newPage);
    smoothScrollToTop(100); // Adjust duration as needed
};


const loadRooms = async (page) => {
  // setRooms([{},{},{},{}])

  setHasMoreRooms(true);
  let roomsArr = [];
  let pinnedRooms = [];
  let favoritesRooms = [];
  let excludedFlowIds = new Set();

  // Determine which user ID to use for fetching useLater based on the context
  let userIdForQuery = id; // Assume 'id' is the ID of the profile being viewed

  // Fetch the useLater flows to exclude them for any scenario except when currentUser views their own 'useLater'
  // if (currentUser && props.filterBy !== "useLater") {
  //   const useLaterSnapshot = await firebase.firestore().collection("useLater")
  //     .where("username", "==", id)
  //     .get();
  //   // excludedFlowIds = new Set(useLaterSnapshot.docs.map(doc => doc.data().flowId));
  // }

  if (props.filterBy === "useLater") {
      // setIsLoading(true);
    
      try {
        const bookmarksSnapshot = await firestore.collection("bookmarks")
          .where("username", "==", currentUser.displayName)
          .get();
    
        const flowIds = bookmarksSnapshot.docs.map(doc => doc.data().flowId);
    
        let newRooms = [];
    
        // Only proceed with the query if there are flow IDs to filter on
        if (flowIds.length > 0) {
          const flowsQuery = firestore.collection("flows")
            .where("flowId", "in", flowIds)
            .orderBy("date", "desc");
    
          const querySnapshot = await flowsQuery.get();
    
          newRooms = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
        } else {
          console.log("No bookmarks found for user, or no flows match the bookmarked IDs.");
        }
    
        setRooms(newRooms);
       
      } catch (e) {
        console.error("Error loading rooms with bookmarked flows: ", e);
      }
    
      setIsLoading(false);
    
    
  }
  
  
  


  if (props.filterBy === "favorites") {
    setIsLoading(true);

    try {
        let excludeFlowIds = new Set(); // Set to store flow IDs to exclude

        // Optionally fetch excluded Flow IDs as in the "flows" block
        // Populate excludeFlowIds based on your criteria
        // Example:
        // excludeFlowIds.add("someFlowId");

        const favoritesQuery = firestore.collection("likes")
            .where("username", "==", userIdForQuery)
            // .orderBy("createdAt", "desc"); // Ensure consistent ordering for pagination

        let favoritesSnapshot;
        if (currentPage === 0) {
            favoritesSnapshot = await favoritesQuery.limit(pageSize).get();
        } else {
            favoritesSnapshot = await favoritesQuery.startAfter(lastDocs[currentPage - 1]).limit(pageSize).get();
        }

        console.log(`Fetched ${favoritesSnapshot.docs.length} favorite likes.`);

        // Extract flowIds from the "likes" documents
        // Assuming flowId is stored in a field called "flowId"
        const favoritesFlowIds = favoritesSnapshot.docs
            .map(doc => doc.data().flowId)
            .filter(flowId => flowId !== undefined && flowId !== null);

        console.log(`Extracted ${favoritesFlowIds.length} flowIds from favorites.`);

        const validFavoritesFlowIds = favoritesFlowIds.filter(flowId => !excludeFlowIds.has(flowId));

        console.log(`Valid favorites flowIds after excluding: ${validFavoritesFlowIds.length}`);

        // Deduplicate flowIds to prevent fetching the same flow multiple times
        const uniqueFavoritesFlowIds = Array.from(new Set(validFavoritesFlowIds));

        console.log(`Unique favorites flowIds: ${uniqueFavoritesFlowIds.length}`);

        // Firestore 'in' queries allow a maximum of 10 items
        const BATCH_SIZE = 10;
        const batches = [];

        for (let i = 0; i < uniqueFavoritesFlowIds.length; i += BATCH_SIZE) {
            const batchFlowIds = uniqueFavoritesFlowIds.slice(i, i + BATCH_SIZE);
            batches.push(
                firestore.collection("flows")
                    .where("__name__", "in", batchFlowIds)
                    .get()
            );
        }

        // Execute all batches concurrently
        const favoritesRoomsSnapshots = await Promise.all(batches);

        // Flatten the array of query snapshots
        const allFavoritesDocs = favoritesRoomsSnapshots.flatMap(snapshot => snapshot.docs);

        console.log(`Fetched ${allFavoritesDocs.length} flow documents for favorites.`);

        let favoritesRooms;

        // if (currentUser !== null && currentUser.displayName !== userIdForQuery) {
        //     favoritesRooms = allFavoritesDocs
        //         .filter(doc => doc.exists && doc.data().visibility === "public")
        //         .map(doc => ({ id: doc.id, ...doc.data() }));
        // } else {
            favoritesRooms = allFavoritesDocs
                .filter(doc => doc.exists)
                .map(doc => ({ id: doc.id, ...doc.data() }));
        //}

        console.log(`Processed ${favoritesRooms.length} favorite rooms.`);

        setRooms(favoritesRooms);

        if (favoritesSnapshot.docs.length > 0) {
            setLastDocs(prevLastDocs => {
                const newLastDocs = [...prevLastDocs];
                newLastDocs[currentPage] = favoritesSnapshot.docs[favoritesSnapshot.docs.length - 1];
                return newLastDocs;
            });
        }

        // Adjust pagination based on the number of rooms actually displayed
        if (favoritesRooms.length < pageSize) {
            setHasMoreRooms(false); // No more rooms to fetch
        } else {
            setHasMoreRooms(true);
        }

    } catch (error) {
        console.error("Error loading favorite rooms:", error);
    } finally {
        setIsLoading(false);
    }
}

if (props.filterBy === "flows") {
  setIsLoading(true);

  try {
    const collectionsToQuery = ["pinned", "reshared", "paidPromo", "bookmarks"];
    const statusIDs = {
      pinned: new Set(),
      reshared: new Set(),
      paidPromo: new Set(),
      bookmarks: new Set(),
    };

    // **1. Fetch All Relevant Statuses**
    for (const collectionName of collectionsToQuery) {
      try {
        const querySnapshot = await firestore
          .collection(collectionName)
          .where("username", "==", userIdForQuery)
          .get();

        const flowIds = querySnapshot.docs
          .map((doc) => {
            const data = doc.data();
            return data.flowId;
          })
          .filter((flowId) => flowId !== undefined && flowId !== null);

        statusIDs[collectionName] = new Set(flowIds);
      } catch (error) {
        console.error(`Error fetching collection "${collectionName}":`, error);
        statusIDs[collectionName] = new Set();
      }
    }

    // **2. Fetch Pinned Flows**
    const pinnedFlowIds = Array.from(statusIDs.pinned);
    let pinnedFlows = [];
    if (pinnedFlowIds.length > 0) {
      const pinnedFlowsPromises = pinnedFlowIds.map((flowId) =>
        firestore.collection("flows").doc(flowId).get()
      );
      const pinnedFlowsDocs = await Promise.all(pinnedFlowsPromises);

      pinnedFlows = pinnedFlowsDocs
        .filter((doc) => doc.exists)
        .map((doc) => {
          const docData = doc.data();
          const flowId = doc.id;

          return {
            id: flowId,
            ...docData,
            isPinned: true,
            isPinnedBy: userIdForQuery,
            isReshared: statusIDs.reshared.has(flowId),
            resharedBy: statusIDs.reshared.has(flowId) ? userIdForQuery : undefined,
            isPaidPromo: statusIDs.paidPromo.has(flowId),
            paidPromoBy: statusIDs.paidPromo.has(flowId) ? userIdForQuery : undefined,
          };
        });
    }

    // **3. Prepare the Base Query for Non-Pinned Flows**
    let baseQuery = firestore.collection("flows").orderBy("date", "desc");

    if (props.filterBy === "flows") {
      baseQuery = baseQuery.where("username", "==", userIdForQuery);
    }

    if (currentUser.displayName !== id) {
      baseQuery = baseQuery.where("visibility", "==", "public");
    }

    // **4. Dynamic Fetch Size Adjustment**
    // To account for potential exclusions, we'll fetch more documents than the pageSize
    const EXTRA_FETCH_MULTIPLIER = 2; // Fetch twice the pageSize
    let fetchSize = pageSize * EXTRA_FETCH_MULTIPLIER;
    let lastDoc = null;

    let paginatedQuery = baseQuery;

    if (currentPage > 0 && lastDocs[currentPage - 1]) {
      paginatedQuery = paginatedQuery.startAfter(lastDocs[currentPage - 1]);
    }

    // **5. Fetch Non-Pinned Flows**
    const querySnapshot = await paginatedQuery.limit(fetchSize).get();
    const fetchedDocs = querySnapshot.docs;

    const nonPinnedFlows = fetchedDocs
      .filter((doc) => {
        const flowId = doc.id;
        const isPinned = statusIDs.pinned.has(flowId);
        const isBookmarked = statusIDs.bookmarks.has(flowId);
        return !isPinned && !isBookmarked;
      })
      .map((doc) => {
        const docData = doc.data();
        const flowId = doc.id;

        return {
          id: flowId,
          ...docData,
          isPinned: false,
          isPinnedBy:userIdForQuery,
          isReshared: statusIDs.reshared.has(flowId),
          resharedBy: statusIDs.reshared.has(flowId) ? userIdForQuery : undefined,
          isPaidPromo: statusIDs.paidPromo.has(flowId),
          paidPromoBy: statusIDs.paidPromo.has(flowId) ? userIdForQuery : undefined,
        };
      });

    // **6. Update lastDoc for Pagination**
    if (fetchedDocs.length > 0) {
      lastDoc = fetchedDocs[fetchedDocs.length - 1];
      setLastDocs((prevLastDocs) => {
        const newLastDocs = [...prevLastDocs];
        newLastDocs[currentPage] = lastDoc;
        return newLastDocs;
      });
    }

    // **7. Combine Pinned and Non-Pinned Flows**
    let newRooms;
    if (currentPage === 0) {
      // **Reserve Space for Pinned Flows on the First Page**
      newRooms = [...pinnedFlows, ...nonPinnedFlows];
      newRooms = newRooms.slice(0, pageSize); // Ensure total rooms do not exceed pageSize
    } else {
      newRooms = nonPinnedFlows.slice(0, pageSize);
    }

    // **8. Sort the Combined Rooms**
    newRooms.sort((a, b) => {
      if (a.isPinned && !b.isPinned) return -1;
      if (!a.isPinned && b.isPinned) return 1;
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });

    // **9. Set the Rooms State**
    setRooms(newRooms);

    // **10. Determine if There Are More Rooms to Load**
    if (fetchedDocs.length < fetchSize) {
      setHasMoreRooms(false);
    } else {
      setHasMoreRooms(true);
    }

    // **11. Debugging Logs (Optional)**
    console.log(`Current Page: ${currentPage}`);
    console.log(`Pinned Flows Count: ${pinnedFlows.length}`);
    console.log(`Fetched Non-Pinned Flows Count: ${nonPinnedFlows.length}`);
    console.log(`Total Rooms Set: ${newRooms.length}`);
  } catch (error) {
    console.error("Error loading rooms with augmented statuses:", error);
  } finally {
    setIsLoading(false);
  }
}


};

if (isPrivate === null) {
  // Optional: You can return a loader or placeholder while checking Firestore
  return <div>Loading...</div>;
}

// This component renders only when isPrivate is checked
if (isPrivate === true) {
return (
  <div style={{position: 'absolute',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'}}>
    {isPrivate ? (
      <div><div style={{display:'flex', justifyContent:'center'}}><p style={{fontFamily:'quicksand', color:'white', fontSize:18, marginRight:7}}>This profile is</p><p style={{fontFamily:'quicksand', color:'#F16262', fontSize:18}}>private</p></div>
      
      <p style={{fontFamily:'quicksand', fontSize:14, color:'white', marginTop:10}}>You must follow each other to view their flows.</p>
      </div>
    ):""}
  </div>
);
}


if(isLoading) {
  return(<div style={{height:'calc(100vh - 700px)', width:'100%', backgroundColor:'#323232', display:'flex', justifyContent:'center', alignItems:"center", flexDirection:'column'}}>
        <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <h1>Content Loaded!</h1>
      )}
    </div>
    <p style={{fontFamily:'quicksand', color:'white'}}>loading</p>
    
    </div>)
}

if(isBlocked) {
  return( <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <img src="./blocked-icon.svg" style={{height:70}} />
    <div style={{ display: "flex", marginTop: 20 }}>
      <p
        style={{
          color: "white",
          fontFamily: "quicksand",
          fontSize: 18,
          marginRight: 10,
        }}
      >
        You are
      </p>
      <p
        style={{
          color: "#F06263",
          fontFamily: "quicksand",
          fontSize: 18,
        }}
      >
        blocked
      </p>
    </div>
    <p
      style={{
        color: "white",
        fontFamily: "quicksand",
        opacity: 0.5,
        fontSize: 18,
        opacity: 0.5,
        flexWrap: "wrap",
        position: "relative",
        display: "flex",
        /* width: 100%; */
        flexWrap: "wrap",
        /* margin-left: 20px; */
        /* margin-right: 20px; */
        wordBreak: "break-word",
        marginLeft: 20,
        marginRight: 20,
        textAlign: "center"
      }}
    >
      You cannot follow, message, or view this account's
      flows.
    </p>
  </div>)
}


if(isPrivate) {
  return( <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <img src="./blocked-icon.svg" style={{height:70}} />
    <div style={{ display: "flex", marginTop: 20 }}>
      <p
        style={{
          color: "white",
          fontFamily: "quicksand",
          fontSize: 20,
          marginRight: 10,
        }}
      >
        This account is 
      </p>
      <p
        style={{
          color: "#F06263",
          fontFamily: "quicksand",
          fontSize: 20,
        }}
      >
        private
      </p>
    </div>
    <p
      style={{
        color: "white",
        fontFamily: "quicksand",
        opacity: 0.5,
        fontSize: 20,
        opacity: 0.5,
        flexWrap: "wrap",
        position: "relative",
        display: "flex",
        /* width: 100%; */
        flexWrap: "wrap",
        /* margin-left: 20px; */
        /* margin-right: 20px; */
        wordBreak: "break-word",
        marginLeft: 20,
        marginRight: 20,
        textAlign: "center"
      }}
    >
      You can not see this account's flows.
    </p>
  </div>)
}



  return (
    <>
      <div
        style={{
          width: window.innerWidth <= 700 ? 485 : "100%",
          maxWidth: window.innerWidth <= 700 ? "100%" : 1010,
          margin: "auto",
          paddingLeft: 20,
          paddingRight: 20,
   
        }}
      >
        <main
          style={{
            position: "relative",
            // maxWidth: 960,
            width: "100%",
            margin: "auto",
          }}
        >
          {" "}
          <div className="room-container">
            <div className="room-content">
              <div className={rooms.length == 0 ? "" : "room-grid"}>
              {/* {!isLoading && rooms.length === 0 ? (<div style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', marginTop:150, position: 'relative',
    left: 0}}>
                  <img src="/images/not-available-icon.svg" style={{height:80, width:80}} />
                  <p style={{fontFamily:'quicksand', color:'white', fontSize:17, marginTop:10}}>The are currently no flows.</p>
                </div>):""}
              */}
                    {rooms.map((room, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <RoomProvider value={room}>
                          <RoomPost />
                        </RoomProvider>
                      </div>
                    ))}
                {rooms.length == 0 ? (
                  <div
                    style={{
                      position: "relative",
                      margin: "auto",
                      height: "100%",
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pointerEvents: "none",
                    }}
                  >
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}> 
                                    <img src="images/not-available-icon.svg" style={{height:80, width:80, marginBottom:30}}/>

                    <p className="no-items">There are currently no flows.</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {rooms.length !== 0 ? (<div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom:50
              }}
            >
              <div
                style={{
                  height: 47,
                  width: 140,
                  backgroundImage:`url(/images/previous.svg)`,
                  backgroundRepeat:'no-repeat',
                  marginTop:20,
                  cursor:'pointer',
                  marginBottom:30
                }}
                onClick={() => handlePageChange(currentPage - 1)}
              >
              </div>
              <div
                 style={{
                  height: 47,
                  width: 90,
                  backgroundImage:`url("/images/next.svg")`,
                  backgroundRepeat:'no-repeat',
                  marginTop:20,
                  cursor:'pointer',
                  marginBottom:30
                }}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                 
              </div>
            </div>):""}
          </div>
        </main>
        <ReportModal />
        <PaymentModal />
        

      </div>
    </>
  );
});

const FlowCard = ({ data: { id, thumbnail } }) => (
  <div
    style={{
      border: "0px ",
      position: "relative",
    }}
    className="card"
  >
    {/* Here you need to replace RoomPostMemo with the actual component you want to render, and pass the necessary props */}
    {/* <RoomPostMemo comments={comments} details={details} /> */}
    test
  </div>
);
export default React.memo(ScrollProfile);
