import { default as React, useContext, useEffect, useState } from "react";
import styles from './SidebarMenu.module.css'; // Adjust the import path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import DetailsMenu from "../Home/DetailsMenu";
import { EditorStoreContext } from "../../store/EditorStore";
import { AuthContext } from "../Home/AuthContext.js"; 
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";
import { RoomContext } from "../Home/RoomContext.js"; // Make sure to import the correct context
import '../styles/TabPanelMobile.css';
import { CommentsStoreContext } from "../../store/CommentsStore.js";
import { FlowStoreContext } from "../../store/FlowStore";
import { RemixableStoreContext } from "../../store/RemixableStore.js";
import { ShareStoreContext } from "../../store/ShareStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { TabStoreContext } from "../../store/TabStore.js";

import { firebase } from "../firebase/firebase.js";
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate  } from 'react-router-dom';

import BlockModal from './BlockModal';
import ErrorModal from './ErrorModal';
import RateLimit from "../Home/RateLimit.js";

import moment from "moment";
import ReactImageFallback from "react-image-fallback";
import { Link } from "react-router-dom";
const InfoComponent = () => {
  const EditorStore = useContext(EditorStoreContext);

  const [isPanelOpen, setIsPanelOpen] = useState(true);

  // const collaborators = []; // Load or pass this as needed

  // const toggleList = () => setIsListOpen(!isListOpen);
  // const toggleDetails = () => {setIsDetailsOpen(!isDetailsOpen); setIsListOpen(false)}
  const [commentsNumber, setCommentsNumber] = useState(0);
  const [openBlockModal, setOpenBlockModal] = useState(false);

  const [tryAgain, setTryAgain] = useState(false);
  const [pending, setPending] = useState(false);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const RemixableStore = useContext(RemixableStoreContext);
  const [openComments, setOpenComments] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const WidgetStore = useContext(WidgetStoreContext);
  const TabStore = useContext(TabStoreContext);

  const CommentsStore = useContext(CommentsStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const ShareStore = useContext(ShareStoreContext);
  const [openModalError, setOpenModalError] = useState(false)

  const AuthenticationStore = useContext(AuthenticationStoreContext)

  const [likesNumber, setLikesNumber] = useState(0);
  const [isDefaultContentOpen, setIsDefaultContentOpen] = useState(true); // State for default content visibility

  const [isListOpen, setIsListOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [openModalRateLimit, setOpenModalRateLimit] = useState(false);

  const toggleList = () => {
    const shouldOpen = !isListOpen;
    setIsListOpen(shouldOpen);
    setIsDetailsOpen(false);
    setIsDefaultContentOpen(!shouldOpen);
  };

  const toggleDetails = () => {
    const shouldOpen = !isDetailsOpen;
    setIsDetailsOpen(shouldOpen);
    setIsListOpen(false);
    setIsDefaultContentOpen(!shouldOpen);
  };


  let { id } = useParams(); // If you need to access params
  let flowId = id;
  const { currentUser } = useContext(AuthContext);
  const room = useContext(RoomContext);

  const [collaborators, setCollaborators] = useState([]);
  const StyledText = ({ text }) => {
    try {
    // Regex to identify @usernames and #hashtags
    const tokenRegex = /(@\w+|#[\w]+)/g;
    
    // Split text into parts and map @usernames and #hashtags to components
    const parsedText = text.split(tokenRegex).map((part, index) => {
        if(part.startsWith === undefined) {
          return;
        }
        if (part.startsWith('@')) {
            const username = part.slice(1).toLowerCase(); // Remove '@' and convert to lowercase
            return (
                <Link key={index} to={`/${username}`} style={{ color: 'rgb(0, 148, 255)' }}>
                    @{username}
                </Link>
            );
        } else if (part.startsWith('#')) {
            const hashtag = part.slice(1); // Remove '#' for the URL
            // Define colors for hashtags in a cycle
            const colors = ['#FF5C5C', '#0094FF', '#DF5FFF'];
            const color = colors[index % colors.length]; // Cycle through colors based on index
            return (
                <Link key={index} to={`/search/${hashtag}`} style={{ color }}>
                    {part}
                </Link>
            );
        }
        return part; // Normal text remains unchanged
      
    });

    return (
        <div>{parsedText}</div>
    );
  } catch(e) {}
};

  useEffect(() => {
    // fetchCollaborators(EditorStore.flowId);
}, [EditorStore.flowId]); 

const addPinnedNotification = async (
  sender,
  receiver,
  commentContent,
  photoURL
) => {
  const newNotification = {
    sender: currentUser.displayName,
    receiver: EditorStore.username,
    type: "pinnedFlow",
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    additionalData: {
      photoURL: currentUser.photoURL,
    },
    flowId:EditorStore.flowId,
    isSeen:false
  };

  await firebase.firestore().collection("notifications").add(newNotification);
};


useEffect(() => {
  let isMounted = true; // flag to check if component is mounted
  // IIFE to handle async operation
  (async () => {
    const docLike = await firebase
      .firestore()
      .collection("pinned")
      .doc(currentUser.uid + ":" + EditorStore.flowId)
      .get();

    if (docLike.exists && isMounted) {
      setIsPinned(true);
    }
  })();

  // Cleanup function
  return () => {
    isMounted = false; // set flag to false when component unmounts
  };
}, []); // Dependency array is empty, so this effect runs only once on mount




const pinFlow = async () => {
  if (!AuthenticationStore.userId) {
    console.log("User not authenticated.");
    AuthenticationStore.setShowModal(true);
    return;
  }

  const currentTime = Date.now();
  if (currentTime - lastClickTime > 3000) {
    // Reset the counter if more than 3 seconds have passed since the last recorded click
    setClickCount(0);
  }

  // Increment click count and update last click time
  setClickCount(prevCount => prevCount + 1);
  setLastClickTime(currentTime);

  // Allow up to 3 rapid clicks before enforcing a cooldown
  if (clickCount >= 20) {
    WidgetStore.setErrorModalMessage("You clicked too many times. Please wait a few seconds and try again.")
    setOpenModalRateLimit(true);
    setTryAgain(true);  // Make sure setTryAgain is implemented to handle retry logic
    return;
  }

  setPending(true);
  const docPin = await firebase
    .firestore()
    .collection("pinned")
    .doc(AuthenticationStore.userId + ":" + EditorStore.flowId)
    .get();

  if (docPin.exists) {
    setIsPinned(false);
    setPinned(pinned - 1);
    await firebase
      .firestore()
      .collection("pinned")
      .doc(AuthenticationStore.userId + ":" + EditorStore.flowId)
      .delete();
    setPending(false);
  } else {
    setIsPinned(true);
    setPinned(pinned + 1);

    await firebase
      .firestore()
      .collection("pinned")
      .doc(AuthenticationStore.userId + ":" + EditorStore.flowId)
      .set({
        flowId: EditorStore.flowId,  // Verify 'id' usage, replace with 'flowId' if necessary
        likeId: AuthenticationStore.userId + ":" + EditorStore.flowId,
        userId: AuthenticationStore.userId,
        timestamp: new Date().toISOString(),
        username:currentUser.displayName,
        isPinnedBy: currentUser.displayName
      }, { merge: true });
    setPending(false);
    addPinnedNotification();
  }
};


  useEffect(() => {
    let isMounted = true; // flag to check if the component is still mounted
    if(currentUser == null) {
      return;
    }
    (async () => {
      const querySnapshot = await firebase
        .firestore()
        .collection("blocked")
        .where("blockedUser", "==", EditorStore.username)
        .where("userId", "==", currentUser.uid)
        .get();
      
      // Check if the query returned any documents
      if (!querySnapshot.empty && isMounted) {
        setIsBlocked(true);
      }
    })();
  
    // Cleanup function to set the isMounted flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, []); // The effect runs only once on component mount
  
  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
    // IIFE to handle async operation
    (async () => {
      const docLike = await firebase
        .firestore()
        .collection("pinned")
        .doc(currentUser.uid + ":" + EditorStore.flowId)
        .get();
  
      if (docLike.exists && isMounted) {
        setIsPinned(true);
      }
    })();
  
    // Cleanup function
    return () => {
      isMounted = false; // set flag to false when component unmounts
    };
  }, []); // Dependency array is empty, so this effect runs only once on mount
  
  return (
    <div className={styles.desktopContainer}>
            <div
                      style={{
                        width: 300,
                        backgroundColor: "rgb(63, 63, 62);",
                        padding: 20,
                        display: "flex",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        zIndex:1000
                      }}
                    >
                      <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                      <img src={'/images/info-icon.svg'} style={{height:20, width:20, marginRight:7}}/>
                      <p
                        style={{
                          color: "white",
                          fontFamily: "Quicksand",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: 18,
                        }}
                      >
                        Info
                      </p>
                      </div>
                      <div onClick={()=>{
                                 
                                 TabStore.setClosePanel(true);

                                
                    }} style={{ 
                          display: "block",
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          cursor: "pointer",
                          backgroundImage:'url(/images/close-circle.svg)', backgroundSize:'contain', height:20, width:20, marginTop:15, marginRight:10}}></div>
                    </div>

    {EditorStore.username ? (
      <div className={styles.desktopOverlayPanel}>
        
        <div className={styles.desktopButtonContainer}>
          
          <div style={{display: 'flex', zIndex: 10}}>
            <div style={{height: 33, width: 33, borderRadius: '50%', border: '1px solid white', marginRight: 10, overflow: 'hidden'}}>
              <img src={EditorStore.urlPhoto} style={{height: 30, width: 30}} alt="User"/>
            </div>
            <span style={{display: 'flex', flexDirection: 'column'}}>
              <p style={{fontFamily: 'quicksand', color: 'white', fontSize: 14.7, marginBottom: -3}}>{`@${EditorStore.username}`}</p>
              <p style={{color: 'white', fontSize: 11, fontFamily: 'quicksand', opacity: 0.4, fontWeight: 100}}>{moment(EditorStore.date).fromNow()}</p>
            </span>
            <button onClick={toggleList} className={styles.toggleButton} style={{marginLeft:10, marginRight:10, marginTop:2}}>
              <p style={{fontFamily:'quicksand', color:'#1c1c1c', fontSize:10, fontWeight:'bold'}}>{isListOpen ? '-' : '+'}{collaborators.length}</p>
            </button>
          </div>
          <img src="/images/ellipsis-desktop.svg" onClick={toggleDetails} className={styles.ellipsisButton}></img>
        </div>
        <div className={styles.desktopContentArea}>
          {isListOpen && (
            <div className={styles.overlayList}>
              <ul>
                {collaborators.map((item, index) => (
                  <li key={index} style={{position: 'relative'}}>
                    <div style={{display: 'flex'}}>
                      <div style={{height: 33, width: 33, borderRadius: '50%', border: '1px solid white', marginRight: 10, marginLeft: 7, marginBottom: 10, overflow: 'hidden'}}>
                        <ReactImageFallback
                      className="header-profile-icon"
                      src={
                        item.urlPhoto !== null &&
                        item.urlPhoto !== undefined &&
                        item.urlPhoto !== ""
                        ? item.urlPhoto :
                        "/images/Flowroom_Default_Avatar.png"
                      }
                      style={{ size: 30 }}
                      fallbackImage="/images/Flowroom_Default_Avatar.png"
                      initialImage="/images/Flowroom_Default_Avatar.png"
                    />


                      </div>
                      <span style={{position: 'absolute', marginTop: -2, left: 55}}>
                        <p style={{fontFamily: 'quicksand', fontSize: 15, color: 'white'}}>{`@${item.username}`}</p>
                        <p style={{fontFamily: 'quicksand', fontSize: 11, color: 'white', opacity: 0.4, fontWeight: 100}}>{item.collaboratorRole}</p>
                      </span>
                    </div>
                  </li>
                ))}
                {collaborators.length === 0 && (
                  <p style={{color: 'white', fontFamily: 'quicksand', textAlign: 'left', fontSize: 14}}>There are no collaborators listed for this flow.</p>
                )}
              </ul>
            </div>
          )}
          {isDetailsOpen && (
            <div style={{height:'100vh', width:'100%', backgroundColor:'red', position:'relative', top:0, color:'white'}}>
                    <div
        id={`flow-options-${flowId}-desktop`}
        style={{
          backgroundColor: "#1C1C1C",
          height: "100vh",
          width: "300px",
          position: "absolute",
          display: "block",
          zIndex: 10000,
        }}
      >
        <div
          style={{
            display: "flex",

            marginBottom: 10,
            padding: 0,
            background: "#1C1C1C",
            height: "100%",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            onClick={async () => {
              if (currentUser == null) {
                // AuthenticationStore.setShowModal(true);
                AuthenticationStore.setRoomShowLoginModal(true);
                return;
              }
              pinFlow()
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <img src="/images/Frame.svg" style={{ height: 20 }} />
            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",
                cursor: "pointer",
                fontSize:14
              }}
            >
              {!isPinned ? "Pin Flow" : "Unpin Flow"}
            </p>
          </div>
          {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 15,
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faUser}
                  color="#4FD975"
                  size={"1x"}
                  style={{ display: "flex" }}
                />
                <p
                  style={{
                    marginLeft: 10,
                    fontFamily: "quicksand",
                    color: "#4FD975",
                    cursor: "pointer",
                  }}
                >
                  Follow {`@${props.username}`}
                </p>
              </div> */}
          <div
            onClick={() => {
              if (currentUser == null) {
                // AuthenticationStore.setShowModal(true);
                AuthenticationStore.setRoomShowLoginModal(true);
                return;
              }
              let roomData = {
                blocked: EditorStore.username,
                user: firebase.auth().currentUser.displayName,
              };

              if (firebase.auth().currentUser.displayName !== EditorStore.username) {
                // WidgetStore.setShowBlockModal(true);

                setOpenBlockModal(true);
                WidgetStore.setCurrentlyBlockingUser(EditorStore.username);
              } else {
                // WidgetStore.setShowErrorModal(true);
                WidgetStore.setErrorModalMessage("");

                WidgetStore.setErrorModalMessage("You can not block yourself");
                setOpenModalError(true);
              }
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <img src="/images/block-mobile.svg" style={{ height: 20 }} />

            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",

                cursor: "pointer",
                fontSize:14

              }}
            >
              {!isBlocked ? "Block" : "Unblock"} {`@${EditorStore.username}`}

            </p>
          </div>
          <div
            onClick={() => {
              if (currentUser == null) {
                // AuthenticationStore.setShowModal(true);
                AuthenticationStore.setRoomShowLoginModal(true);
                return;
              }
              WidgetStore.setShowReportModal(true);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <img src="/images/mobile-flag.svg" style={{ height: 20 }} />

            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",
                cursor: "pointer",
                fontSize:14

              }}
            >
              Report flow
            </p>
          </div>
          {/* <div
          onClick={() => {
            if (firebase.auth().currentUser == null) {
              AuthenticationStore.setShowModal(true);
              return;
            }
            // document.getElementById(
            //   `flow-options-${props.flowId}`
            // ).style.display = "none";
            // document.getElementById(
            //   `flow-remixes-${props.flowId}`
            // ).style.display = "flex";
            // loadRemixes();
            setLoadRemixes(true);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              backgroundImage: "url(/images/Flowroom_Remix_Icon_Green.svg)",
              backgroundSize: "237% 107%",
              height: "35.7px",
              width: "37px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "-28px 0px",
            }}
          ></div>
          <p
            style={{
              marginLeft: 10,
              fontFamily: "quicksand",
              color: "#4FD975",
              cursor: "pointer",
            }}
          >
            Remixes
          </p>
        </div> */}
        <Link to={`/remixes/${EditorStore.flowId}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <img src="/images/tick-square.svg" style={{ height: 20 }} />

            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",
                cursor: "pointer",
                fontSize:14
              }}
            >
              Remixes
            </p>
          </div>
          </Link>
        </div>
      </div>
            </div>
          )}
          {isDefaultContentOpen && (
            <p style={{color: 'white', fontFamily: 'quicksand', fontSize: 12, textAlign: "left", position: 'relative', fontWeight:100, wordBreak:'break-all'}}>
              <StyledText text={EditorStore.description} />
            </p>
          )}
        </div>
      </div>
    ) : (
      <div style={{backgroundColor: '#1c1c1c'}}>
        <p style={{color: 'white', textAlign: 'center', fontFamily: 'quicksand'}}>
          There are no details available for this flow. If you feel this is an error, refresh and try again.
        </p>
      </div>
    )}
        <BlockModal
          openModal={openBlockModal}
          closeModal={() => {
            setOpenBlockModal(false);
          }}
          isBlocked={(param)=>{
            setIsBlocked(true);
            setOpenBlockModal(false)

          }}
          isUnBlocked={(param)=>{
            setIsBlocked(false);
            setOpenBlockModal(false)

          }}
          username={EditorStore.username}
          
        />
        <ErrorModal
          openModalError={openModalError}
          closeError={() => {
            setOpenModalError(false);
          }}
        />
        <RateLimit
        
        openModalRateLimit={openModalRateLimit}
        closeError={() => {
          setOpenModalRateLimit(false);
        }}
        />
  </div>
  );
};

export default React.memo(InfoComponent);